<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="">
        <div class="">
          <label class="txt-right required">Phương tiện giao hàng</label>
          <!-- <div class="p-col"> -->
          <Textarea
            v-model="vehicle.name"
            :autoResize="true"
            rows="1"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="name" />
          <!-- </div> -->
        </div>
        <div class="">
          <label class="txt-right required mt-3">Công ty</label>
          <MultiSelect
              class="text-box"
              v-model="vehicle.company_id"
              :options="company_list"
              optionLabel="name"
              optionValue="id"
              placeholder="Chọn"
          >
            <template #value="slotProps">
              <span v-for="(option, index) of slotProps.value" :key="option.code">{{ getCompanyName(option) }}<span v-if="index !== slotProps.value.length - 1">; </span>
              </span>
              <template v-if="!slotProps.value || slotProps.value.length === 0">
                Chọn
              </template>
            </template>
          </MultiSelect>
          <input class="input_tmp_validator" id="company" />
        </div>
        <label class="txt-right required mt-3">Loại phương tiện</label>
        <!-- <div class="p-col"> -->
        <div class="flex flex-wrap gap-3">
          <div class="flex align-items-center">
            <RadioButton
              v-model="vehicle.type"
              inputId="type1"
              name="truck"
              value="1"
              @change="getVehicleList()"
            />
            <label for="type1" class="ml-2 radio-option">Xe tải</label>
          </div>
          <div class="flex align-items-center mt-2">
            <RadioButton
              v-model="vehicle.type"
              inputId="type2"
              name="bike"
              value="2"
              @change="getVehicleList()"
            />
            <label for="type2" class="ml-2 radio-option">Xe máy</label>
          </div>
        </div>
        <label class="txt-right required mt-3">Phân loại</label>
        <!-- <div class="p-col"> -->
        <div class="flex flex-wrap gap-3">
          <div class="flex align-items-center">
            <RadioButton
              v-model="vehicle.classify"
              inputId="classify1"
              name="internal"
              value="1"
            />
            <label for="classify1" class="ml-2 radio-option">Nội bộ</label>
          </div>
          <div class="flex align-items-center mt-2">
            <RadioButton
              v-model="vehicle.classify"
              inputId="classify2"
              name="external"
              value="2"
              @click="getVehicleList()"
            />
            <label for="classify2" class="ml-2 radio-option">Thuê ngoài</label>
          </div>
        </div>
        <div v-show="vehicle.classify == 2">
          <label class="txt-right required pt-3">Phương tiện hiển thị trong báo cáo</label>
          <Dropdown
          v-model="vehicle.vehicle_id"
          :options="vehicle_list"
          optionLabel="name"
          optionValue="id"
          placeholder="Chọn"
          class="text-box"
          />
          <input class="input_tmp_validator" id="vehicle_id" />
        </div>
        <div class="flex justify-content-center mt-3">
          <Checkbox v-model="vehicle.active" :binary="true" />Hiển thị
        </div>
      </div>

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning return-style-button"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="p-button-success main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from "@/core/DataServices";
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
  },
  data() {
    return {
      vehicle: {
        name: "",
        type: "1",
        classify: "1",
        active: true,
        vehicle_id: null,
        company_id: [],
      },
      company_list: [],
      vehicle_list: [],
      code: "",
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role: [],
      saving: false,
      addressData: {
        street_number: "251",
        route: "Quang Trung",
        administrative_area_level_1: "Thành phố Hồ Chí Minh",
        country: "Việt Nam",
        latitude: 10.8286588,
        longitude: 106.6732573,
      },
      placeResultData: {
        address_components: [
          { long_name: "251", short_name: "251", types: ["street_number"] },
          {
            long_name: "Quang Trung",
            short_name: "Quang Trung",
            types: ["route"],
          },
          {
            long_name: "Gò Vấp",
            short_name: "Gò Vấp",
            types: ["administrative_area_level_2", "political"],
          },
          {
            long_name: "Thành phố Hồ Chí Minh",
            short_name: "Thành phố Hồ Chí Minh",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "Việt Nam",
            short_name: "VN",
            types: ["country", "political"],
          },
        ],
        adr_address:
          '<span class="street-address">251 Quang Trung</span>, <span class="extended-address">Phường 10</span>, <span class="locality">Gò Vấp</span>, <span class="region">Thành phố Hồ Chí Minh</span>, <span class="country-name">Việt Nam</span>',
        formatted_address:
          "251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam",
        geometry: {
          location: { lat: 10.8286588, lng: 106.6732573 },
          viewport: {
            south: 10.8273459697085,
            west: 106.6719226697085,
            north: 10.8300439302915,
            east: 106.6746206302915,
          },
        },
        icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
        name: "251 Quang Trung",
        place_id: "ChIJ8-A91QMpdTERWj0x_fnFL2Y",
        plus_code: {
          compound_code: "RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam",
          global_code: "7P28RMHF+F8",
        },
        reference: "ChIJ8-A91QMpdTERWj0x_fnFL2Y",
        types: ["street_address"],
        url: "https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a",
        utc_offset: 420,
        html_attributions: [],
        utc_offset_minutes: 420,
      },
    };
  },
  async mounted() {
    // var that = this;
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model:this.model", this.model);
      this.vehicle.name = this.model.name;
      //vehicle.type = tostring(this.model.type)

      this.vehicle.type = this.model.type.toString();
      this.vehicle.classify = this.model.classify.toString();
      await this.getVehicleList();
      this.vehicle.vehicle_id = this.model.vehicle_id;
      this.vehicle.active = this.model.active;
      var list_id_company = [];
      if(this.model.vehicle_companies){
        for(var i=0;i<this.model.vehicle_companies.length;i++){
          var item_data = this.model.vehicle_companies[i];
          list_id_company.push(item_data.company_id)
        }
      }
      this.vehicle.company_id = list_id_company;
      // this.user = this.$commonFuction.convertJsonObject(this.model);
      // this.$CoreService.getAccountByUid(this.model.id).then((res) => {
      //   console.log("getAccount", res);
      //   if (res && res.length) {
      //     this.user.user_id = res[0].uid;
      //   }
      // });
    }
    this.listGroupRole().then((res) => {
      console.log("resres", res);
      this.list_group_role = res;
    });
    await this.getCompanyList();
    // console.log("LMAO", this.getElementById("vehicle_id"));
  },
  methods: {
    async getCompanyList() {
      var gqLQueryList = DataServices.getList("company");
      console.log("gqL", gqLQueryList);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          orderBy: { created_at: "desc" },
        },
      });
      var listData = resData.data["company"];
      this.company_list = listData;
    },
    getCompanyName(id){
      for (let i = 0; i < this.company_list.length; i++){
        if (this.company_list[i].id == id) return this.company_list[i].name;
      }
    },
    async getVehicleList(){
      var where_key = {
            deleted : {_eq : false},
            active : {_eq : true },
            type : {_eq : this.vehicle.type},
            classify : {_eq : 1},
        }
        let {data} = await this.$apollo.query({
            query: DataServices.getList('vehicle', {"fields" : `id name`}),
            variables: {
              where_key: where_key,
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_Vehicle = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_Vehicle.push({id : e.id, name: e.name});
        }
        this.vehicle_list = list_Vehicle;
    },
    async listGroupRole() {
      var where = {
        active: { _eq: true },
        deleted: { _eq: false },
      };
      var order_by = { id: "desc" };
      var listData = await this.$CoreService.getListData(
        "group",
        where,
        null,
        null,
        order_by
      );
      return listData;
    },
    async getGroupUser(uid) {
      var table_cer = "group_user";
      var where = { uid: { _eq: uid } };
      var gqLQueryList = await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key: where,
        },
      });
      var listData = resData.data[table_cer];
      return listData;
    },

    getAddressData: function (addressData, placeResultData, id) {
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      console.log(JSON.stringify(addressData));
      console.log(JSON.stringify(placeResultData));
      console.log(id);
    },
    backToList() {
      this.$emit("back");
    },
    async validateData() {
      this.validationErrors = {};
      console.log("VALIDATETETETE");
      let name_add = document.getElementById("name");
      if (
        this.vehicle.name == undefined ||
        this.vehicle.name == null ||
        this.vehicle.name == ""
      ) {
        console.log("LMAO");
        name_add.setCustomValidity("Phương tiện không được để trống!");
      } else if (this.vehicle.name.length > 255) {
        name_add.setCustomValidity("Phương tiện không được quá 255 ký tự!");
      } else {
        name_add.setCustomValidity("");
      }
      
      
      let vehicle_add = document.getElementById("vehicle_id");
      if (this.vehicle.classify == 2 && this.$commonFuction.isEmpty(this.vehicle?.vehicle_id)) {
        console.log("LMAOOOO");
        vehicle_add.setCustomValidity("Phương tiện hiển thị trong báo cáo không được để trống!");
      } else {
        vehicle_add.setCustomValidity("");
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log("test=====================");
        inpObj.reportValidity();
        return true;
      }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return;
      }

      if (Object.entries(this.validationErrors).length !== 0)
        this.$commonFuction.is_loading(false);

      if (this.vehicle.classify == 1){
        this.vehicle.vehicle_id = null;
      }
      // if (!this.account_exist) {
      var params = {
        name: this.vehicle.name,
        type: this.vehicle.type,
        classify: this.vehicle.classify,
        active: this.vehicle.active,
        vehicle_id: this.vehicle.vehicle_id,
        company_id: this.vehicle.company_id,
      };

      console.log("saveData", params);
      this.$emit("save", params);
      // }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        let acceptTags = [
          "jpeg",
          "jpg",
          "gif",
          "png",
          "JPEG",
          "JPG",
          "PNG",
          "GIF",
        ];
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split(".").pop())) {
            alert(
              "Please upload the image file in the following format: jpeg, jpg, gif, png"
            );
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [],
            arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }

          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === "") {
              this.imagesDeletes.push(this.images[0]);
            }
            this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            await this.validateData();
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === "") this.imagesDeletes.push(img);
    },
  },
  /*apollo: {
        category: {
          query() {
            return DataServices.getList('category', {"fields" : "id name"});
          },
          update(data) {
            if(data.category){
              this.categories = data.category
            }
          },
          fetchPolicy: "network-only"
        },
      }*/
};
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.category-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container {
  height: 40px;
}

label {
  font-weight: bold;
}

.radio-option {
  margin-bottom: 0 !important;
}
.required:after {
  content: " *";
  color: red;
}
.p-checkbox {
  margin-right: 1rem !important;
}
</style>
